<!-- 访客新增详情跳转页面-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="60%"
    :close-on-click-modal="false"
    @close="closeModal"
  >
  <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="基本信息">
        <!-- 基本信息 -->
        <a-descriptions layout="vertical" title="基本信息">
          <a-descriptions-item label="姓名">
            {{ baseInfoList.residentName ? baseInfoList.residentName : "--" }}
          </a-descriptions-item>
          <a-descriptions-item label="身份证号">
            {{ baseInfoList.identityCard ? baseInfoList.identityCard : "--" }}
          </a-descriptions-item>
          <a-descriptions-item label="性别">
            {{ baseInfoList.gender }}
          </a-descriptions-item>
          <a-descriptions-item label="年龄">
            {{ baseInfoList.age }}
          </a-descriptions-item>
          <a-descriptions-item label="出生日期">
            {{ baseInfoList.birthDate }}
          </a-descriptions-item>
          <a-descriptions-item label="民族">
            {{
              baseInfoList.nation_dictText ? baseInfoList.nation_dictText : "--"
            }}
          </a-descriptions-item>
          <a-descriptions-item label="文化程度">
            {{
              baseInfoList.educationalLevel_dictText
                ? baseInfoList.educationalLevel_dictText
                : "--"
            }}
          </a-descriptions-item>
          <a-descriptions-item label="婚姻状态">
            {{ baseInfoList.maritalStatus ? baseInfoList.maritalStatus : "--" }}
          </a-descriptions-item>
          <a-descriptions-item label="政治面貌">
            {{
              baseInfoList.politicalStatus_dictText
                ? baseInfoList.politicalStatus_dictText
                : "--"
            }}
          </a-descriptions-item>
          <a-descriptions-item label="兵役状态">
            {{
              baseInfoList.militaryStatus ? baseInfoList.militaryStatus : "--"
            }}
          </a-descriptions-item>
          <a-descriptions-item label="宗教信仰">
            {{ baseInfoList.religion ? baseInfoList.religion : "--" }}
          </a-descriptions-item>
          <a-descriptions-item label="职业">
            {{ baseInfoList.job ? baseInfoList.job : "--" }}
          </a-descriptions-item>
          <a-descriptions-item label="照片">
            <imagePreview ref="img1" :businessId="businessId"></imagePreview>
          </a-descriptions-item>
        </a-descriptions>
        <!-- 联系信息 -->
        <a-descriptions style="word-break: break-word;word-wrap: break-word;white-space: pre-wrap;" layout="vertical" title="联系信息">
          <a-descriptions-item label="联系方式">
            {{ baseInfoList.phone ? baseInfoList.phone : "--" }}
          </a-descriptions-item>
          <a-descriptions-item label="所属网格">
            {{ baseInfoList.cellName1 ? baseInfoList.cellName1 : "--" }}
          </a-descriptions-item>
          <a-descriptions-item label="小区">
            {{ baseInfoList.cellName ? baseInfoList.cellName : "--" }}
          </a-descriptions-item>
          <a-descriptions-item label="楼栋">
            {{ baseInfoList.buildingNum ? baseInfoList.buildingNum : "--" }}
          </a-descriptions-item>
          <a-descriptions-item label="单元">
            {{ baseInfoList.unitNum ? baseInfoList.unitNum : "--" }}
          </a-descriptions-item>
          <a-descriptions-item label="户号">
            {{ baseInfoList.accountNum ? baseInfoList.accountNum : "--" }}
          </a-descriptions-item>
          <a-descriptions-item label="详细地址">
            {{
              baseInfoList.cellName
                ? `${baseInfoList.cellName}/${baseInfoList.buildingNum}/${baseInfoList.unitNum}/${baseInfoList.floorNum}/${baseInfoList.accountNum}`
                : "--"
            }}
          </a-descriptions-item>
        </a-descriptions>
        <!-- 户籍信息 -->
        <a-descriptions layout="vertical" title="户籍信息">
          <a-descriptions-item label="是否户主">
            {{
              baseInfoList.flag
                ? baseInfoList.flag === "0"
                  ? "是"
                  : "否"
                : "--"
            }}
          </a-descriptions-item>
          <a-descriptions-item label="户籍地">
            {{
              baseInfoList.residencePlace ? baseInfoList.residencePlace : "--"
            }}
          </a-descriptions-item>
        </a-descriptions>
        <!-- 家庭成员 -->
        <div
          style="
            border-left: 4px solid #556bff;
            font-size: 0.08rem;
            font-weight: bold;
            color: black;
            line-height: 100%;
            margin-bottom: 15px;
          "
        >
          &nbsp;家庭成员
        </div>
        <a-table
          :data-source="personTableData"
          :pagination="false"
          :columns="columns"
          bordered
        >
          <div slot="houseHolderRelation" slot-scope="text, record">
            {{
              record.houseHolderRelation_dictText
                ? record.houseHolderRelation_dictText
                : "--"
            }}
          </div>
          <span slot="action" slot-scope="record" class="table-operation">
            <a style="color: #e6a23c" @click="watch(record)">详情</a>
          </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="补充信息" force-render>
    
    <!-- 家庭情况 -->
    <a-descriptions layout="vertical" title="家庭情况">
      <a-descriptions-item label="婚姻状况">
        {{
          personInforList.maritalStatus_dictText
            ? personInforList.maritalStatus_dictText
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="子女数量">
        {{ personInforList.childrenNum ? personInforList.childrenNum : "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="子女姓名">
        {{ personInforList.childrenName ? personInforList.childrenName : "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="紧急联系人">
        {{
          personInforList.emergencyContact
            ? personInforList.emergencyContact
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="联系人关系">
        {{
          personInforList.contactsRelation_dictText
            ? personInforList.contactsRelation_dictText
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="子女联系电话">
        {{
          personInforList.childrenPhone ? personInforList.childrenPhone : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="紧急联系人电话">
        {{
          personInforList.emergencyContactPhone
            ? personInforList.emergencyContactPhone
            : "--"
        }}
      </a-descriptions-item>
    </a-descriptions>
    <!-- 健康情况 -->
    <a-descriptions layout="vertical" title="健康情况">
      <a-descriptions-item label="是否自理">
        {{
          personInforList.selfCareFlag
            ? personInforList.selfCareFlag === "0"
              ? "是"
              : "否"
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="是否有慢性病">
        {{
          personInforList.chronicDiseaseFlag
            ? personInforList.chronicDiseaseFlag === "0"
              ? "是"
              : "否"
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="是否残疾">
        {{
          personInforList.disabilityFlag
            ? personInforList.disabilityFlag === "0"
              ? "是"
              : "否"
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="最近一次体检日期">
        {{
          personInforList.physicalExaminationDate
            ? personInforList.physicalExaminationDate
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="体检结果">
        {{
          personInforList.physicalExaminationResult_dictText
            ? personInforList.physicalExaminationResult_dictText
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="医保类型">
        {{
          personInforList.medicalInsuranceType_dictText
            ? personInforList.medicalInsuranceType_dictText
            : "--"
        }}
      </a-descriptions-item>
    </a-descriptions>
    <!-- 其他情况 -->
    <a-descriptions layout="vertical" title="其他情况">
      <a-descriptions-item label="收入来源">
        {{
          personInforList.incomeSource_dictText
            ? personInforList.incomeSource_dictText
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="是否建档立卡">
        {{
          personInforList.filingFlag
            ? personInforList.filingFlag === "0"
              ? "是"
              : "否"
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="是否享受特助">
        {{
          personInforList.assistFlag
            ? personInforList.assistFlag === "0"
              ? "是"
              : "否"
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="照料人员">
        {{
          personInforList.assistType_dictText
            ? personInforList.assistType_dictText
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="是否纳入社区关怀名单">
        {{
          personInforList.careListFlag
            ? personInforList.careListFlag === "0"
              ? "是"
              : "否"
            : "--"
        }}
      </a-descriptions-item>
    </a-descriptions>
      </a-tab-pane>
    </a-tabs>
  </el-dialog>
</template>
<script>
import api from "./api";
import imagePreview from "./image-preview/imagePreview";
export default {
  components: {
    imagePreview,
  },
  data() {
    return {
      title: "",
      visible: false,
      businessId: "",
      disabled: false,
      flag: "",
      fileIds: [], //图片数组
      personInforList: {},
      baseInfoList: {},
      nameList: [],
    };
  },
  watch: {
    // 解决页面不刷新图片请求不发送问题
    businessId(newval) {
      console.log(newval, "412");
      this.$nextTick(() => {
        this.$refs.img1.getPicList(newval);
      });
    },
  },
  computed: {},
  mounted() {},
  methods: {
    openModal(record = {}) {
      this.visible = true;
      this.businessId = record.residentId;
      this.title = record.title;
      this.getPersonInfo(record.id);
      this.getBaseInfo(record.residentId);
    },
    // 身份证号计算性别/年龄/出生日期
    calculateIDCardInfo(idCard) {
      let info = {
        gender: "",
        age: "",
        birthDate: "",
      };

      if (!idCard || (idCard.length !== 15 && idCard.length !== 18)) {
        return info;
      }

      let year =
        idCard.length === 15 ? "19" + idCard.substr(6, 2) : idCard.substr(6, 4);
      let month = idCard.substr(idCard.length === 15 ? 8 : 10, 2);
      let day = Number(idCard.substr(idCard.length === 15 ? 10 : 12, 2)) + 1;

      let genderCode =
        idCard.substr(idCard.length - 2, 1) % 2 === 0 ? "女" : "男";
      let birthday = new Date(year, parseInt(month) - 1, day); // 将月份减一
      let age = new Date().getFullYear() - birthday.getFullYear();
      info.gender = genderCode;
      info.age = age;
      info.birthDate = birthday.toISOString().slice(0, 10);
      this.baseInfoList.age = info.age;
      return info;
    },
    // 取消
    closeModal() {
      this.visible = false;
      this.$emit("refsh");
    },
    // 根据ID查询图片
    async picVisitor(id) {
      const res = await api.picVisitor(id);
      console.log(res, "260");
    },

    // 根据id获取基本信息详情
    async getBaseInfo(id) {
      const res = await api.getResidentArchivesById(id);
      console.log(res, "165");
      this.baseInfoList = res.data;
      this.calculateIDCardInfo(res.data.identityCard);
    },

    // 根据id获取详情
    async getPersonInfo(id) {
      const res = await api.getElderlyById(id);
      this.personInforList = res.data;
      this.flag = res.data.flag;
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}

/deep/.el-dialog {
  border-radius: 20px !important;
}

/deep/.ant-descriptions-title {
  font-size: 0.08rem;
  border-left: 4px solid #556bff;
  padding-left: 0.5%;
}

/deep/.ant-descriptions-item-label {
  color: #7d8da6 !important;
}

/deep/.ant-descriptions-item-content {
  color: #2d3138;
}
/deep/.ant-tabs .ant-tabs-ink-bar{
  background-color: #2A5CFF !important;
}
/deep/.ant-tabs .ant-tabs-tab-active {
  color: #2A5CFF !important;
  font-weight: 700;
}
/deep/.ant-tabs-nav .ant-tabs-tab:hover{
  color: #2A5CFF !important;
}
</style>